import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [];

export const dictionary = {
		"/(authenticated)": [4,[2]],
		"/(authenticated)/accounting": [5,[2]],
		"/(authenticated)/accounting/fs/[id]": [6,[2]],
		"/(authenticated_2)/company/[id]": [13,[3]],
		"/(authenticated)/deals": [7,[2]],
		"/(authenticated)/drop": [8,[2]],
		"/(authenticated_2)/fs/[id]": [14,[3]],
		"/(authenticated)/funds": [9,[2]],
		"/(authenticated_2)/fund/[id]": [15,[3]],
		"/login": [17],
		"/(authenticated)/lps": [10,[2]],
		"/(authenticated_2)/lp/[id]": [16,[3]],
		"/(authenticated)/portfolios": [11,[2]],
		"/(authenticated)/profile": [12,[2]],
		"/register": [18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';